section.our-mission {
    background-color: white;
    padding: 20px 32px;
    h3 {
		@include trump(22px, 600);
		color: $bcg-green;
		letter-spacing: 5px;
		text-transform: uppercase;
		text-align: center;
        margin-bottom: 20px;
     
    }
    p {
		@include nunito(12px, normal, 1.6em);
        color: $bcg-blackish;
        padding-bottom:24px;
    
  
	}
    @media screen and (min-width: 768px){
        padding: 0;
        display: flex;
        flex-direction: row;
        .text {
            h3 {
                text-align: left;
            }

            width: 60%;
            padding: 60px;
            @media screen and (min-width: 960px){
				padding: 90px;

            }
            @media screen and (min-width: 1200px){
                padding: 120px 140px;
				p {
					font-size: 18px;
                }
                h3{
                    font-size: 32px;
                }
            }
            @media screen and (min-width: 1600px) {
                padding: 220px 200px;

				p {
					max-width: 540px;
					font-size: 20px;
				}
            }
        }
    }

	.graphic {
        display: none;
        @media screen and (min-width: 768px){
            display: block;
            width: 40%;
            background: $bcg-red url('../media/images/blockchain-gaming-technology-mobile.jpg') center center no-repeat;
            background-size: cover;
        }
        @media screen and (min-width: 960px){
            display: block;
            width: 40%;
            background: $bcg-red url('../media/images/blockchain-gaming-technology.jpg') center center no-repeat;
            background-size: cover;
        }
        @media screen and (min-width: 1600px) {
            width: 40%;
        }
	}

	a.button {
        margin-bottom: 14px;
       margin:auto;
        @media screen and (min-width: 576px){
            padding: 8px 24px;
            margin:0;

        }
    }
    

}

button,
a.button {
	display: block;
	background-color:#92CD28;
	color: $bcg-light-gray;
	border: none;
	padding: 6px;
	max-width: 160px;
	text-align: center;
	text-transform: uppercase;
	@include trump(18px);
	letter-spacing: 3px;
	margin: 28px 0;
	text-decoration: none;
}
