footer {
	padding: 30px;
	padding-top: 20px;

	h3 {
		@include trump(22px, 600);
		color: $bcg-green;
		letter-spacing: 5px;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 20px;
	}

	p {
		@include nunito(12px, normal, 1.6em);
		color: $bcg-blackish;
    }
    button {
        display: block;
        background-color: $bcg-green;
        color: $bcg-light-gray;
        border: none;
        padding: 6px;
        max-width: 160px;
        text-align: center;
        text-transform: uppercase;
        @include trump(18px);
        letter-spacing: 3px;
        margin: 28px 0;
        text-decoration: none;
    }
}




form {
	input {
		border: none;
		border-radius: 2px;

		&[type='text'],
		&[type='email'] {
			background-color: white;
			color: $bcg-darkgray;
			padding: 8px;
			width: 100%;
			display: block;
			@include nunito(16px, 20px);

			&::placeholder {
				color: #717171;
				@include nunito(14px, 20px);
			}
		}
	}
}

footer {
	background-color: $bcg-light-gray;
	padding: 0;
	@media screen and (min-width: 576px){
		h3{
			font-size: 28px;
			@media screen and (min-width: 768px){
				margin-bottom: 30px;
            }
            @media screen and (min-width: 960px) {
                font-size: 32px;
            }
            @media screen and (min-width: 1200px){
                margin-bottom: 50px;
            }
		}
	}


	form {
		@media screen and (min-width: 768px){
     		padding: 60px;
		}

		padding: 32px;

		.fields {
			display: grid;
			grid-gap: 0.75em !important;
			border: none;
			padding: none;
            width: 100%;
            button.alt-submit {
                display: none;
            }           
            
			@media screen and (min-width: 768px){
				grid-template-columns: 50% 50%;
				input {
					padding: 12px;
                }
            }
                @media screen and (min-width: 1200px){
                    padding: 40px 80px;
                    grid-template-columns: repeat(3, 1fr);
    
                    input {
                        font-size: 18px;
                        padding: 16px;
                    }
    
                    input[name='comments'] {
                        grid-column: 1 / span 2;
                    }
    
                    button.alt-submit {
                        display: block;
                        margin: 0;
                        width: 100%;
                        max-width: 100%;
                    }
    
                }

		}

		button {
			padding: 6px 18px;
			margin: 20px auto;
			&:hover {
				background-color: darken($bcg-red, 5%);
            }
            @media screen and (min-width: 1200px){
                display: none;
            }
		}
	}

	.logo {
		background-color: $bcg-blackish;
		width: 100%;
        padding: 10px;
        @media screen and (min-width: 768px){
			padding: 30px 0;

			img {
				height: 50px;
			}
        }
        @media screen and (min-width: 1600px){
            padding: 100px 0;

			img {
				height: 70px;
			}
        }

		img {
			display: block;
			height: 50px;
			width: auto;
			margin: auto;
		}
	}
}