a {
    text-decoration: none !important;
  }

  .bioText {
    color: #cdcdcd;
    max-width: 600px;
    margin: auto;
    width: 80%;
    min-width: 129px;
    padding:50px 10px;
  }

  .bioText p {
    font-family: "nunito-sans";
    font-size: 14px;
    line-height: 1.4rem;
    a{
      color: #cdcdcd;
      
    
    }
  }

  .lity-content:after {
    box-shadow: none;
  }

  .leadership-team li img {
    filter: contrast(90%);
  }

  .leadership-team li:hover img {
    filter: contrast(110%);
    transition: all 0.5s;
  }

  .leadership-team li:hover a span,
  .leadership-team li:hover div span {
    color: $bcg-green;
    transition: all 0.5s;
  }

  @media screen and (min-width: 768px) {
    .bioText p {
      font-size: 16px;
      line-height: 1.6rem;
    }
  }

  @media screen and (min-width: 1024px) {
    .bioText {
      max-width: 900px;
    }

    .bioText p {
      font-size: 18px;
      line-height: 1.75rem;
    }
  }

  @media screen and (min-width: 1400px) {
    .bioText {
      max-width: 960px;
    }

    .bioText p {
      font-size: 20px;
      line-height: 1.75rem;
    }
  }