//@tailwind base;
@tailwind components;


@import "_mixins";
@import "_variables";
@import "_globals";
@import "_header";
@import "_services";
@import "_leadership";
@import "_bioText";
@import "_mission";
@import "_footer";

@tailwind utilities;
