// Mobile first
html{
	margin:0px;
}
#header {
	background: $bcg-blackish url('../media/images/blockchain-gaming-planet-mobile.jpg') no-repeat center left;
	background-size: cover;
	padding: 30px;
	padding-bottom: 60px;


	h2 {
		color: white;
		@include trump(34px, bold, 1.2em);
		text-transform: uppercase;
		margin-top: 30px;

		&:after {
			content: '';
			display: block;
			width: 60px;
			height: 4px;
			background-color: $bcg-red;
			margin: 18px 0;
		}
	}

	summary {
		@include nunito(15px, normal, 1.25em);
		color: white;
	}
}

@media screen and (min-width: 576px) {
	#header {
	

		h2 {
			font-size: 44px;
		}

		summary {
			max-width: 66%;
			font-size: 14px;
		}
	}
}

// Medium size screens

@media screen and (min-width: 768px) {
	#header {
	

		h2 {
			font-size: 50px;

			&:after {
				margin-bottom: 30px;
			}
		}

		summary {
			font-size: 14px;
			margin-bottom: 40px;
			max-width: 400px;
		}
	}
}
@media screen and (min-width: 960px) {
	#header {
		background: $bcg-blackish url('../media/images/blockchain-gaming-planet.jpg') no-repeat center left;
		background-size: cover;
		padding: 60px;
		padding-left: 100px;
		padding-bottom: 140px;

		h1 {
			font-size: 32px;
			margin-bottom: 80px;
		}

		h2 {
			font-size: 60px;

			&:after {
				width: 90px;
			}
		}

		summary {
			font-size: 16px;
			max-width: 500px;
		}
	}
}
@media screen and (min-width: 1200px) {
	#header {
		padding: 60px;
		padding-left: 100px;
		padding-bottom: 140px;

	
		h2 {
			font-size: 72px;

			&:after {
				width: 100px;
				margin-bottom: 40px;
			}
		}

		summary {
			font-size: 18px;
		}
	}

}



@media screen and (min-width: 1600px) {
	#header {
		padding: 60px;
		padding-left: 100px;
		padding-bottom: 200px;

		h1 {
			font-size: 34px;
			margin-bottom: 120px;
		}

		h2 {
			font-size: 82px;
			margin-left: 40px;

			&:after {
				width: 110px;
				margin-bottom: 40px;
			}
		}

		summary {
			font-size: 22px;
			margin-left: 40px;
			max-width: 600px;
		}
	}


}