// Fonts Mixins

@mixin trump($size, $weight: normal, $lineheight: initial) {
	font-family: trumpgothicpro, sans-serif;
	font-size: $size;
	font-weight: $weight;
	line-height: $lineheight;
}

@mixin nunito($size, $weight: normal, $lineheight: initial) {
	font-family: nunito-sans, sans-serif;
	font-size: $size;
	font-weight: $weight;
	line-height: $lineheight;
}