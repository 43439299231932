section {
	&.our-services {
		background-color: $bcg-light-gray;
		padding: 30px;
		padding-top: 20px;

		h3 {
			@include trump(22px, 600);
			color: $bcg-red;
			letter-spacing: 5px;
			text-transform: uppercase;
			text-align: center;
			margin-bottom: 20px;
		}

		p {
			@include nunito(12px, normal, 1.6em);
			color: $bcg-blackish;

		}



		ul.services-listing {
			margin: 32px 0;
			display: grid;
			grid-template-columns: 50% 50%;
			grid-row-gap: 40px;
			grid-column-gap: 20px;

			.area {
				display: block;
			}

			h4 {
				@include trump(20px, bold);
				text-transform: uppercase;

				&:after {
					content: '';
					display: block;
					width: 40px;
					height: 4px;
					background-color: $bcg-red;
					margin: 12px 0;
				}
			}
		}

		p {
			text-align: center;
			max-width: 600px;
			margin: auto;
			margin-bottom: 40px;
		}

		.area ul li {
			@include nunito(12px, normal, 2em);
		}

		ul.services-listing {
			max-width: 80%;
			margin: auto;

			li {
				h4 {
					font-size: 24px;
				}
			}
		}

		li.area {
			ul {
				li {
					font-size: 14px;
				}
			}
		}
	}
}



// High res mobile

@media screen and (min-width: 576px) {
	section {
		&.our-services {
			h3 {
				font-size: 28px;
			}

			p {
				font-size: 14px;
			}
		}

	}

}

// Medium size screens

@media screen and (min-width: 768px) {
	section {
		&.our-services {
			padding: 60px;

			h3 {
				margin-bottom: 32px;
			}

			p {
				font-size: 15px;
				margin-bottom: 32px;
			}
		}

	}

}

@media screen and (min-width: 960px) {
	section {
		&.our-services {
			h3 {
				font-size: 32px;
			}

			ul.services-listing {
				max-width: 100%;
				grid-template-columns: repeat(4, 1fr);
				grid-column-gap: 1.5em;
			}
		}
	}


}


// Large size screens

@media screen and (min-width: 1200px) {

	section {

		&.our-services {
			p {
				margin-bottom: 60px;
			}

			ul.services-listing {
				max-width: 100%;
				display: flex;
				justify-content: space-around;
				margin-bottom: 80px;

				li.area {
					max-width: 220px;

					h4 {
						font-size: 28px;

						&:after {
							width: 50px;
							margin: 20px 0;
						}
					}

					ul {
						li {
							font-size: 18px;
						}
					}
				}
			}
		}


	}

}