section {
  &.leadership-team {
    background-color: $bcg-blackish;
    padding: 1.2rem 0;

    h3 {
      @include trump(22px, 600);
      color: $bcg-green;
      letter-spacing: 5px;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 20px;
    }
    h2 {
			@include trump(22px, 600);
			color: $bcg-red;
			letter-spacing: 5px;
			text-transform: uppercase;
			text-align: center;
			margin-bottom: 20px;
		}

    p {
      @include nunito(12px, normal, 1.6em);
      color: $bcg-blackish;
    }

    ul {
      display: grid;
      

      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin: 10px 0;

        img {
          width: 90px;
          height: 90px;
          &.fred-img {
            object-fit: cover !important;
            object-position: center !important;
          }
        }

        .details {
          min-width: 200px;
          display: block;
          @media screen and(min-width: 1200px) {
            min-height: 110px;
          }
        }

        .name {
          display: block;
          color: $bcg-light-gray;
          @include trump(19px, normal, 30px);
          letter-spacing: 1px;
          text-transform: uppercase;
          width: 180px;
          @media screen and (min-width: 768px) {
            line-height: 2rem;
          }
          @media screen and(min-width: 1200px) {
            width: inherit;
          }
        }

        .title {
          display: block;
          color: white;
          @include nunito(12px, 400);
          font-style: italic;
          max-width: 174px;
        }
      }
    }
  }
}
@media screen and (min-width: 375px) {
  section {
    &.leadership-team {
      ul {
        li {
          margin: 10px auto;

          width: 353px;
          max-width: 100%;
        }
      }
    }
  }
}
@media screen and (min-width: 576px) {
  section {
    &.leadership-team {
      padding: 20px 10px;

      h3 {
        font-size: 28px;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        li {
          margin: 36px 0;

          img {
            margin-right: 16px;
          }
          .title {
            max-width: 100%;
          }
          .name {
            @include trump(19px, normal, 50px);
          }
        }
      }
    }
  }
}

// Medium size screens

@media screen and (min-width: 768px) {
  section {
    &.leadership-team {
      padding: 60px;

      h3 {
        margin-bottom: 32px;
        margin-top: 30px;
      }

      ul {
        li {
          grid-template-columns: auto 1fr;
          display: grid;
          align-items: center;
          img {
            width: 120px;
            height: 120px;
          }

          .details {
            .name {
              font-size: 24px;
            }

            .title {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 960px) {
  section {
    &.leadership-team {
      padding: 40px;

      h3 {
        font-size: 32px;
      }

      ul {
        li {
          img {
            width: 140px;
            height: 140px;
          }
        }
      }
    }
  }
}

// Large size screens

@media screen and (min-width: 1200px) {
  section {
    &.leadership-team {
      padding: 40px;

      ul {
        max-width: 1200px;
        margin: 0 auto;
        li {
          flex-direction: column;
          display: flex;

          align-items: center;
          justify-content: space-around;
          img {
            width: 160px;
            height: 160px;
            margin: 0;
          }

          .details {
            margin-top: 12px;
            text-align: center;
          }
        }
      }
    }
  }
}

section.leadership-team {
  ul {
    @media screen and (min-width: 1200px) {
      justify-content: center;
    }
    li {
      @media screen and (min-width: 576px) {
        width: 353px;
      }
      @media screen and (min-width: 1200px) {
        width: 272px;

        justify-content: space-between;
      }
    }
  }
}

[data-aos^="zoom"][data-aos^="zoom"].aos-animate {
  @media screen and (min-width: 1900px) {
    opacity: 1;
    transform: translateZ(0) scale(1);
  }
}
